@use '../tco-utils' as tco-utils;

@mixin tco-link() {
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  width: auto;
  color: var(tco-utils.get-tco-color-css-var-name(blue, light));
  text-decoration: none;
  line-height: inherit;
  font-family: inherit;
  font-weight: 600;
  font-size: inherit;
  transition: color 0.3s ease-in-out, text-decoration 0.4s ease-in-out;

  &:not(&:disabled, &.disabled) {
    cursor: pointer;

    &:hover {
      color: #237fd3;
      text-decoration: underline;
    }
  }

  &:disabled,
  &.disabled {
    cursor: default;
    color: var(tco-utils.get-tco-color-css-var-name(gray, extra-dark));
  }
}
