// Fonts
@use "styles/fonts.scss";

// TCO theme
@use "styles/themes/tco.theme.scss";

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f5f5f5;
}

// TODO: move to separated file
// MatTooltip style changes
.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip .mdc-tooltip__surface {
      color: #000;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 0;
      font-size: 10px;
      padding: 8px;
    }
  }
}

.icon-dialog-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  border: none;
  color: #0066b2;
  outline: none;
  padding: 0;
  width: 24px;
  height: 24px;
}

#toast-container {
  &.toast-bottom-right {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 9999;
  }

  .ngx-toastr {
    box-shadow: none !important;
    padding: 20px 15px 20px 50px !important;
    border-radius: 5px;
    margin-top: 16px;
    max-width: 500px;
    width: 100%;

    &.toast-success {
      background-color: #4caf50; /* Green color for success */
      color: #fff;
    }

    &.toast-error {
      background-color: #f44336; /* Red color for failure */
      color: #fff;
    }
  }
}
