@use 'sass:map';
@use '../tco-utils' as tco-utils;

// Button themes
$tco-initial-button-theme: (
  background: var(tco-utils.get-tco-color-css-var-name(white)),
  color: var(tco-utils.get-tco-color-css-var-name(black)),
  cursor: pointer,
  hover-theme: (
    background: var(tco-utils.get-tco-color-css-var-name(gray, extra-light)),
    box-shadow: 0 0 4px #28282820,
  ),
  outline-hover-theme: (
    box-shadow: 0 0 4px #28282850,
  ),
  disabled-theme: (
    cursor: default,
    opacity: 0.5,
  ),
);

$tco-basic-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(#fff, light)),
    color: var(tco-utils.get-tco-color-css-var-name(#000)),
    hover-theme: (
      background: #f5f5f5,
      box-shadow: none,
    ),
    outline-hover-theme: (
      box-shadow: none,
    ),
  )
);

$tco-primary-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(blue)),
    color: var(tco-utils.get-tco-color-css-var-name(white)),
    hover-theme: (
      background: #005599,
      box-shadow: 0 0 4px #28282850,
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px #0066b2,
    ),
  )
);

$tco-control-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(navy)),
    color: var(tco-utils.get-tco-color-css-var-name(white)),
    hover-theme: (
      background: #172134,
      box-shadow: 0 0 4px #1e2a41cc,
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px #1e2a41cc,
    ),
  )
);

$tco-warning-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(warning)),
    color: var(tco-utils.get-tco-color-css-var-name(white)),
    hover-theme: (
      background: #f08b18cc,
      box-shadow: 0 0 4px #28282850,
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px #f08b18cc,
    ),
  )
);

$tco-danger-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(danger)),
    color: var(tco-utils.get-tco-color-css-var-name(white)),
    hover-theme: (
      background: #ce2c2c,
      box-shadow: 0 0 4px #e03030cc,
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px #e03030cc,
    ),
  )
);

$tco-success-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(success)),
    color: var(tco-utils.get-tco-color-css-var-name(white)),
    hover-theme: (
      background: #10af5d,
      box-shadow: 0 0 4px rgb(18 191 102 / 80%),
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px rgb(18 191 102 / 80%),
    ),
  )
);

$tco-neutral-button-theme: map.deep-merge(
  $tco-initial-button-theme,
  (
    background: var(tco-utils.get-tco-color-css-var-name(gray)),
    color: var(tco-utils.get-tco-color-css-var-name(black)),
    hover-theme: (
      background: #dcdcdccc,
      box-shadow: 0 0 4px #dcdcdc50,
    ),
    outline-hover-theme: (
      box-shadow: 0 0 4px #dcdcdccc,
    ),
  )
);

// Mixins
@mixin tco-button-type($button-type-theme: $tco-initial-button-theme) {
  $background: map.get($button-type-theme, background);
  $color: map.get($button-type-theme, color);
  $cursor: map.get($button-type-theme, cursor);
  $outline-hover-theme: map.get($button-type-theme, outline-hover-theme);
  $hover-theme: map.get($button-type-theme, hover-theme);
  $disabled-theme: map.get($button-type-theme, disabled-theme);

  $background: map.get($tco-initial-button-theme, background) !default;
  $color: map.get($tco-initial-button-theme, color) !default;
  $cursor: map.get($tco-initial-button-theme, cursor) !default;
  $outline-hover-theme: map.get($tco-initial-button-theme, outline-hover-theme) !default;
  $hover-theme: map.get($tco-initial-button-theme, hover-theme) !default;
  $disabled-theme: map.get($tco-initial-button-theme, disabled-theme) !default;

  background: $background;
  color: $color;
  cursor: $cursor;

  &.disabled,
  &:disabled,
  &[disabled] {
    @each $propertyName, $propertyValue in $disabled-theme {
      #{$propertyName}: $propertyValue;
    }
  }

  &:not(&.disabled, &:disabled, &[disabled]) {
    &:not(.outline) {
      &:hover {
        @each $propertyName, $propertyValue in $hover-theme {
          #{$propertyName}: $propertyValue;
        }
      }
    }

    &.outline {
      &:hover {
        @each $propertyName, $propertyValue in $outline-hover-theme {
          #{$propertyName}: $propertyValue;
        }
      }
    }
  }

  &.outline {
    background: transparent;
    border: 1px solid $background;

    &:not(&.neutral) {
      color: $background;
    }

    &.neutral {
      color: var(tco-utils.get-tco-color-css-var-name(black));
    }
  }
}

@mixin tco-button() {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: auto;
  width: auto;
  background: map.get($tco-initial-button-theme, background);
  color: map.get($tco-initial-button-theme, color);
  cursor: map.get($tco-initial-button-theme, cursor);
  outline: none;
  text-transform: none;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0;
  border-radius: 4px;
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  &:not(&.disabled, &:disabled, &[disabled]) {
    &:not(.outline) {
      &:hover {
        background: var(tco-utils.get-tco-color-css-var-name(gray, extra-light));
        box-shadow: 0 0 4px #28282820;
      }
    }

    &.outline {
      &:hover {
        box-shadow: 0 0 4px #28282850;
      }
    }
  }

  &.sm {
    font-size: 12px;
    padding: 4px 8px;
  }

  &.lg {
    font-size: 14px;
    padding: 10px 20px;
  }

  &.normal {
    font-weight: 400;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.outline {
    background: transparent;
    color: map.get($tco-initial-button-theme, color);
    border: 1px solid map.get($tco-initial-button-theme, color);
  }

  &.basic {
    @include tco-button-type($tco-basic-button-theme);
  }

  &.primary {
    @include tco-button-type($tco-primary-button-theme);
  }

  &.control {
    @include tco-button-type($tco-control-button-theme);
  }

  &.warning {
    @include tco-button-type($tco-warning-button-theme);
  }

  &.danger {
    @include tco-button-type($tco-danger-button-theme);
  }

  &.success {
    @include tco-button-type($tco-success-button-theme);
  }

  &.neutral {
    @include tco-button-type($tco-neutral-button-theme);
  }
}
