@use 'sass:map';

$layers: (
  behind: -1,
  base: 0,
  dropdown: 1000,
  sticky: 1100,
  fixed: 1200,
  modal-backdrop: 1300,
  modal: 1400,
  popover: 2000,
  tooltip: 2100,
  top: 99999,
);

@function get-z-index($layer, $offset: 0) {
  $z-index: 0;

  @if (map.has-key($layers, $layer)) {
    $z-index: map.get($layers, $layer);
  } @else {
    @warn 'Incorrect layer "#{$layer}"';

    $z-index: map.get($layers, base);
  }

  @return $z-index + $offset;
}

@mixin set-layer($layer, $offset: 0) {
  z-index: get-z-index($layer, $offset);
}
