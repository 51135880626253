@use 'sass:map';
@use 'tco-palette' as tco-palette;
@use 'tco-layer' as tco-layer;
@use 'tco-utils' as tco-utils;
@use 'builders/tco-button.builder' as tco-button-builder;
@use 'builders/tco-link.builder' as tco-link-builder;

// install mixin
@mixin install($install-tco-button: true, $install-tco-link: true) {
  :root {
    // provide tco palette css variables
    @each $color-name, $color-palette in tco-palette.$tco-palette {
      @each $color-tone, $color-value in $color-palette {
        #{tco-utils.get-tco-color-css-var-name($color-name, $color-tone)}: #{$color-value};
      }
    }

    // provide tco layers css classes
    @each $layer, $layer-z-index in tco-layer.$layers {
      .#{tco-utils.get-tco-layer-css-class-name($layer)} {
        @include tco-layer.set-layer($layer);
      }
    }

    // customize nb theme for tco needs
    .toastr-overlay-container {
      @include tco-layer.set-layer(popover);
    }

    // include tco-buttons if needed
    @if ($install-tco-button) {
      .tco-button {
        @include tco-button-builder.tco-button();
      }
    }

    // include tco-link if needed
    @if ($install-tco-link) {
      .tco-link {
        @include tco-link-builder.tco-link();
      }
    }
  }
}
